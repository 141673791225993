<template>
  <div class="select-customer">
    <div class="row">
      <el-input style="max-width: 60%; padding-left: 20px; margin-right: 30px;" v-model="customerName" :placeholder="$t('Enter the name of customer')"></el-input>
      <el-button @click="searchCustomer">{{ $t('Search') }}</el-button>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">{{ $t('Select') }}</th>
          <th scope="col">{{ $t('Name') }}</th>
          <th scope="col">{{ $t('Email') }}</th>
          <th scope="col">{{ $t('Phone Number') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in customers">
          <th scope="row"><el-radio v-model="selectedCustomer" :label="customer.id"></el-radio></th>
          <td>{{ customer.customer_name }}</td>
          <td>{{ customer.customer_email }}</td>
          <td>{{ customer.customer_phone }}</td>
        </tr>
      </tbody>
    </table>
    <div class="dialog-footer">
      <el-button type="primary" @click="saveCustomer">{{ $t('Save') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Customer from '@/lib/customer';

export default {
  name: 'SelectCustomer',
  data(){
    return {
      customerName: '',
      customers: [],
      selectedCustomer: '',
    };
  },
  methods:{
    saveCustomer(){
      const selectedCustomer = this.selectedCustomer;
      const customer = this.customers.find(function(customer){
        return selectedCustomer === customer.id;
      });
      this.$emit('customerSelected', customer);
    },
    async searchCustomer(){
      try{
        const loginInfo = Common.getLoginInfo();
        const searchFields = {
          'customer_name': this.customerName,
        };
        const customers = await Customer.searchCustomer(this.apiUrl, searchFields, loginInfo);
        this.customers = customers;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.select-customer{
  .table{
    margin-top: 30px;
  }
  .dialog-footer{
    text-align: right;
    margin-top: 30px;
  }
  .el-radio__label{
    display: none;
  }
}
</style>
